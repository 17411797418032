<template>
  <div>
    <div class="flex mb-6 mt-7">
      <r-button-action
        class="mr-6"
        title="Обновить"
        icon="update"
        color="rocky"
        size="sulguni"
        @click.native="reloadData"
      />
     <!-- <r-button-action
        class="mr-6"
        title="Добавить"
        icon="add"
        color="rocky"
        size="sulguni"
        @click="addDevice()"
      /> -->
    </div>
    <div class="flex mb-6 align-center">
      <r-select
        class="flex-1 mr-6"
        :items="[
          {
            title: 'Все',
            id: 0
          },
          ...$store.state.electric.types
        ]"
        label="Тип устройства"
        v-model="selectedSortType"
        :return-object="true"
      />
      <r-select
        class="flex-1 mr-6"
        :items="[
          {
            id: 0,
            title: 'Все'
          },
          {
            id: -2,
            title: 'Не задан'
          },
          ...$store.state.electric.providers
        ]"
        label="Тип мониторинга"
        v-model="selectedSortProvider"
        :return-object="true"
      />
    <!--  <r-select
        class="flex-1 mr-6"
        :items="sortPing"
        label="Пинг"
        v-model="selectedSortPing"
      />-->
      <r-input
        class="flex-1 mr-6"
        label="Поиск"
        v-model.trim="search"
        after-icon="search"
      />

      <r-button-simple
        size="larishae"
        icon="icon-view"
        icon-size="20"
        @click="selectedView = 'table'"
        :type="selectedView === 'map' ? 'secondary' : 'primary'"
      />
      <r-button-simple
        size="larishae"
        class="ml-2"
        icon="geopoint"
        :type="selectedView === 'map' ? 'primary' : 'secondary'"
        icon-size="20"
        @click="selectedView = 'map'"
      />
    </div>
    <loader v-if="isLoading" />
    <template v-else>
      <div class="flex mt-2 mb-2">
        <div class="ml-auto parmigiano color-titanic opacity-48">
          {{ !!searchText ? "Найдено" : "Всего" }} {{ countObjects }}
        </div>
      </div>

      <div
        v-if="selectedView === 'map'"
        class="objects__map mt-6"
        :key="filteredObjects.length"
        ref="map"
      >
        <rir-map
          show-type
        >
          <div
            v-if="filteredObjects.length > 0">
            <div
              v-for="(marker,index) of filteredObjects"
              :key="`_${marker.id}+${index}`"
            >
              <ymap-marker
                v-if="!!marker.lat && !!marker.lng"
                :coords="[marker.lat, marker.lng]"
                :marker-id="`marker_${marker.id}+${index}`"
                :icon="marker.crashes
                  ? $markerIcon('mapPinRed')
                  : $markerIcon(marker.type.name || 'mapPin')"
                :options="{ hideIconOnBalloonOpen: false }"
                :cluster-name="marker.crashes ? 'red' : 'main'"
                :balloon="{ body: clusterBalloon(marker)}"
              >
                <balloon-card
                  slot="balloon"
                  :marker="marker"
                  :data-id="marker.id"
                />
              </ymap-marker>
            </div>
          </div>
        </rir-map>
      </div>
      <div
        v-else
        class="objects__list mt-6"
      >
        <div
          v-if="countObjects !== 0"
        >
          <scroll-table
            :list="filteredObjects"
            :headings="headings"
            :columns-grid="tableGridColumns"
            :crashes="crashes"
            start-sorted-column="name"
            row-link="/details/"
          />
        </div>
        <not-found v-else />
      </div>
    </template>
  </div>
</template>

<script>
import _ from 'lodash';
import Loader from './Loader';
import BalloonCard from './BalloonCard';
import NotFound from './NotFound';
import rirMap from './RirMap';
import ScrollTable from './Table';
import {formattedDate, wordMatch} from '../helpers/utils';

export default {
  name: 'TabDevices',
  components: {
    Loader,
    BalloonCard,
    NotFound,
    rirMap,
    ScrollTable
  },
  data() {
    return {
      crashes: [],
      selectedView: 'table',
      searchText: '',
      list: [],
      isShowMap: false,
      headings: [
        {
          title: 'Устройство',
          field: 'name'
        },
        {
          title: 'Адрес',
          field: 'address'
        },
        {
          title: 'Тип мониторинга',
          field: 'providerName'
        },
        {
          title: 'Статус',
          field: 'provider'
        }
      ],
      tableGridColumns: [
        '316px',
        '350px',
        '252px',
        '120px'
      ],
      sortType: [
        {
          id: 0,
          title: 'Все'
        },
        {
          id: 1,
          title: 'что-то еще'
        }
      ],
      selectedSortType: {
        id: 0,
        title: 'Все'
      },
      sortProvider: [
        {
          id: 0,
          title: 'Все'
        },
        {
          id: 1,
          title: 'что-то еще'
        }
      ],
      selectedSortProvider: {
        id: 0,
        title: 'Все'
      },
      sortPing: [
        {
          id: 0,
          title: 'По умолчанию'
        },
        {
          id: 1,
          title: 'что-то еще'
        }
      ],
      selectedSortPing: 0
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.electric.isLoading;
    },
    filteredObjects() {
      this.crashes = this.$store.getters.getFormattedCrashesDevices;
      let list = this.$store.getters.getFormattedHosts;
      list = list.map(el => ({
        ...el,
        providerId: el.columns.provider,
        providerCrashes: new Date(
          this.crashes.filter(item => item.hostId == el.hostId)[0]?.columns?.created
          || this.nowDate()
        ).getTime(),
        columns: {
          ...el.columns,
          provider: this.crashes.filter(item => item.hostId == el.hostId).length == 0,
        }
      }));
      // Если заполнена строка поиска
      if (this.searchText) {
        list = list.filter(
          el => wordMatch(el?.columns?.name?.title || '', this.searchText) <= 0.5
            || wordMatch(el?.columns?.address || '', this.searchText) <= 0.5
            || wordMatch(el?.columns?.providerName || '', this.searchText) <= 0.5
        );
      }
      if (this.selectedSortType.id !== 0) {
        list = list.filter(el => (el.type.typeId == this.selectedSortType.id));
      }
      if (this.selectedSortProvider.id !== 0 && this.selectedSortProvider.id !== -2) {
        list = list.filter(el => (el.providerId == this.selectedSortProvider.id));
      }

      if (this.selectedSortProvider.id === -2) {
        list = list.filter(el => (!el.columns?.providerName));
      }
      console.log(list)
      return list;
    },
    countObjects() {
      return this.filteredObjects.length;
    },
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.timeoutId) clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
          this.searchText = typeof val === 'string' ? val.toLowerCase() : null;
        }, 420);
      }
    }
  },
  activated() {
    this.isShowMap = true;
  },
  deactivated() {
    this.isShowMap = false;
  },
  async created() {
    await this.getData();
  },
  methods: {
    nowDate() {
      return formattedDate(new Date(), {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      });
    },
    addDevice() {
      this.$router.push({ path: `new` })
    },
    clusterBalloon(marker) {

      return `<div class="pa-4">
        <a
          href="/${process.env.VUE_APP_BASE_URL}/details/${marker.id}"
          class="pointer data_event${ marker.id }"
          data-marker="${ marker.id }"
        >
          <p class="roquefort mb-1">
            ${ marker.columns.name.title }
          </p>
          <p class="сaprino ekas mb-2">
            ${ marker.columns.providerName }
          </p>
          <div class="flex align-items-center mb-4">
            <p class="tag сaprino mr-2">
              <span class="bladerunner">${ marker.id }</span>
            </p>
            <p class="tag green сaprino">
              <span class="oldboy">${ marker.columns.name.subtitle }</span>
            </p>
          </div>
          <div class="flex align-items-center mozzarella">
            <i class="RIcon mr-2 rir-geopoint_16" style="color: var(--rir-rocky);">
            </i>
            ${ marker.columns.address }
          </div>
        </a>
    </div>`;
    },
    async getData() {
      await this.$store.dispatch('getHosts');
      await this.$store.dispatch('getCrashesDevices');
    },
    reloadData: _.debounce(function () {
      this.getData();
    }, 1000)
  }
};
</script>

<style lang="scss" scoped>

.icon-close {
  cursor: pointer;
  position: absolute;
  margin-left: calc(100% - 60px);
  margin-top: -28px;
}

.icon-div {
  display: table;
  position: relative;
}

.objects {

  &__map {
    height: 700px;
    border-radius: 24px;
    overflow: hidden;
  }
}

::v-deep .rir-popover {
  display: flex;
  align-items: center;

  &__activator {
    width: 100%;
  }

  .rir-icon {
    &.add-icon {
      margin-right: 8px;
    }

    &.data-download__arrow {
      margin-left: 8px;
    }
  }
}

.data-download {
  &__wrapper {
    padding: 12px;

    .rir-button {
      display: flex;
      align-items: center;

      .rir-icon {
        margin-right: 8px;
      }
    }
  }
}

::v-deep .rir-popover__content {
  padding: 8px !important;
}

::v-deep .ya-map-point {
  position: absolute;
  left: -4px;
  top: -4px;
  display: block;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid var(--rir-rocky);
  border-radius: 50%;
}

::v-deep .ya-map-point--edge {
  cursor: pointer !important;
  position: absolute;
  left: -3px;
  top: -3px;
  display: block;
  width: 6px;
  height: 6px;
  background-color: var(--rir-rocky);
  border-radius: 50%;
  opacity: 0.8;
}

::v-deep .rir-popover__content {
  background: red;
}

.date-picker__fly {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
}

</style>
<style lang="scss">
.rir-map-balloon {
  width: 244px;
  font-family: 'Golos UI';

  &__img {
    width: 100%;
    aspect-ratio: 16/10;
    object-fit: cover;
  }

  &__info {
    font-family: 'Golos UI';
    padding: 12px 16px 0;
  }
}

.popover_list {
  display: flex;
  flex-direction: column;
  padding: 12px 0;

  &__item {
    display: flex;
    white-space: nowrap;
    align-items: center;
    opacity: 0.72;
    padding: 14px 16px;
    position: relative;

    &:not(.no_hover) {
      &:hover {
        opacity: 1;
        cursor: pointer;

        &::before {
          content: '';
          position: absolute;
          background-color: #3d75e4;
          height: calc(100% - 8px);
          width: 4px;
          top: 4px;
          left: 0;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }

    &.no_hover {
      opacity: 1;
      cursor: pointer;
    }

  }
}

.tag{
  padding: 0px 4px;
  background: #E6EBF5;
  border-radius: 2px;
  span{
    opacity: 0.48;
  }
  &.green{
    background: #E3F3D8;
  }
}
.r-map-balloon {
  display: block;
  width: 244px;
  font-family: 'Golos UI';
  &__img {
    width: 100%;
    aspect-ratio: 16/10;
    object-fit: cover;
  }

  &__info {
    font-family: 'Golos UI';
    padding: 12px 16px 0;
  }
}

</style>
